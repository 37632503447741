import PdfPageBuilder from "./PdfPageBuilder";
import formatDate from "../helpers/dateToLocalString";
import AppLogo from "@/assets/audit-angel-logo.png";
import InterBold from "@/assets/fonts/Inter/Inter-Bold.ttf";
import InterSemiBold from "@/assets/fonts/Inter/Inter-SemiBold.ttf";
import InterRegular from "@/assets/fonts/Inter/Inter-Regular.ttf";
import { BatchTypes, CliniciansStatusColors } from "../misc/constants";

const styles = {
  headerImagesHeight: 60,
  colors: {
    primary: "#01C6D0",
    secondary: "#EBFAFB",
    orange: "#FF9B63",
    redLight: "#FFF2F1",
  },
  fonts: {
    subtitle: {
      font: ["Inter", "normal"],
      fontSize: 20,
      lineHeight: 1.5,
    },
    h1: {
      font: ["Inter", "semibold"],
      fontSize: 40,
      lineHeight: 1.25,
    },
    h2: {
      font: ["Inter", "semibold"],
      fontSize: 24,
    },
    h3: {
      font: ["Inter", "semibold"],
      fontSize: 20,
    },
    h4: {
      font: ["Inter", "semibold"],
      fontSize: 16,
    },
  },
  spacing: {
    mt2: {
      marginTop: 15,
    },
    mt4: {
      marginTop: 25,
    },
    mb4: {
      marginBottom: 25,
    },
  },
};

export class AuditReportFactory {
  constructor() {
    this.pageBuilder = new PdfPageBuilder();

    this.pageBuilder.loadFont(InterRegular, "Inter", "normal");
    this.pageBuilder.loadFont(InterSemiBold, "Inter", "semibold");
    this.pageBuilder.loadFont(InterBold, "Inter", "bold");
  }

  buildDoctorAuditReport({
    batchName,
    batchDate,
    totalCases,
    totalScore,
    tableSelector,
    casesTableData,
    report,
    accountLogo,
    templateName,
    serviceName,
    createdBy,
  }) {
    const { fonts, colors, spacing } = styles;

    this.pageBuilder.addAbsoluteRect({
      height: 250,
      backgroundColor: colors.secondary,
    });

    this.pageBuilder.relative({ height: 82 }, (pageBuilder) => {
      pageBuilder.addImage(AppLogo, "PNG", {
        width: 135,
        height: styles.headerImagesHeight,
      });

      if (accountLogo) this.addAccountLogo(accountLogo, pageBuilder);

      const [date] = formatDate(batchDate).split(",");
      pageBuilder.addText("Audit Date", {
        marginLeft: 1057,
        font: ["Inter", "semibold"],
        fontSize: 14,
      });
      pageBuilder.addText(date, {
        marginLeft: 1057,
        marginTop: 20,
        fontSize: 14,
      });
    });

    this.pageBuilder.addHorizontalLine();

    this.pageBuilder.addText(batchName, [fonts.h2, spacing.mt2]);
    this.pageBuilder.addText(templateName, [fonts.subtitle]);
    this.pageBuilder.addText(`Service: ${serviceName}`, [fonts.subtitle]);
    this.pageBuilder.addText(`Created By: ${createdBy}`, [fonts.subtitle]);

    this.pageBuilder.addText("Audit Result", [
      fonts.h3,
      spacing.mb4,
      { marginTop: 20 },
    ]);

    this.pageBuilder.relative({ height: 105 }, (pageBuilder) => {
      pageBuilder.addCard(totalCases, "Total Audits Reviewed");
      pageBuilder.addCard(totalScore, "Total Audit Score", {
        marginLeft: 290,
        width: 280,
      });
    });

    this.pageBuilder.addTable(
      {
        html: tableSelector,
        headStyles: {
          fillColor: colors.secondary,
          fontSize: PdfPageBuilder.pxToPt(14),
        },
        beforeCellPrint: ({ cell, row, column }) => {
          if (column.index === 0 && row.section === "body") {
            cell.styles.font = "Inter";
            cell.styles.fontStyle = "semibold";
          }
        },
      },
      { marginLeft: 50, marginRight: 50 }
    );

    this.pageBuilder.addText("Cases", [
      fonts.h3,
      spacing.mb4,
      { marginTop: 20 },
    ]);

    this.pageBuilder.addTable(
      {
        ...casesTableData,
        headStyles: {
          fillColor: colors.secondary,
          fontSize: PdfPageBuilder.pxToPt(14),
        },
        beforeCellPrint: (data) => {
          if (!data.row.raw.score) return;
          if (data.row.raw.score < 75) { 
            data.cell.styles.fillColor = "#FFF2F1"; 
            return;
          };
          if (data.row.raw.score < 90) {
            data.cell.styles.fillColor = "#FFFAEC"; 
            return;
          };
          return;
        },
      },
      { marginLeft: 50, marginRight: 50, marginBottom: 50 }
    );

    if (report) {
      this.pageBuilder.addText("Audit Report", [
        fonts.h3,
        { marginTop: 50 },
      ]);
      this.pageBuilder.addText(report, [spacing.mt4]);
    }

    this.pageBuilder.addPageNumbers();
  }

  addAccountLogo(accountLogo, pageBuilder, marginTop) {
    const { width: imageWidth, height: imageHeight } =
      pageBuilder.doc.getImageProperties(accountLogo);

    const imgRatio = imageHeight / imageWidth;

    pageBuilder.addImage(accountLogo, "PNG", {
      ...marginTop,
      width: styles.headerImagesHeight / imgRatio,
      height: styles.headerImagesHeight,
      marginLeft: 200,
    });
  }

  async buildSpecialAuditReport({
    batchName,
    batchDate,
    templateName,
    totalCases,
    tableSelector,
    report,
    batchType,
    caseGrade,
    createdBy,
    serviceName,
    caseEvaluation,
    sections,
    accountLogo,
  }) {
    const { fonts, colors, spacing } = styles;
    const isGeneral = batchType === BatchTypes.general;

    this.pageBuilder.addAbsoluteRect({
      height: 280,
      backgroundColor: styles.colors.secondary,
    });

    this.pageBuilder.relative({ height: 82 }, (pageBuilder) => {
      pageBuilder.addImage(AppLogo, "PNG", {
        width: 135,
        height: styles.headerImagesHeight,
      });

      if (accountLogo) this.addAccountLogo(accountLogo, pageBuilder);

      const [date] = formatDate(batchDate).split(",");
      pageBuilder.addText("Audit Date", {
        marginLeft: 1057,
        font: ["Inter", "semibold"],
        fontSize: 14,
      });
      pageBuilder.addText(date, {
        marginLeft: 1057,
        marginTop: 20,
        fontSize: 14,
      });
    });

    this.pageBuilder.addHorizontalLine();

    this.pageBuilder.addText(batchName, [fonts.h2, spacing.mt2]);
    this.pageBuilder.addText(templateName, [fonts.subtitle]);
    this.pageBuilder.addText(`Service: ${serviceName}`, [fonts.subtitle]);
    this.pageBuilder.addText(`Created By: ${createdBy}`, [fonts.subtitle]);

    this.pageBuilder.addText("Audit Result", [
      fonts.h3,
      spacing.mb4,
      { marginTop: 50 },
    ]);

    if (!isGeneral)
      this.pageBuilder.addCard(totalCases, "Total Number Of Cases");
    if (isGeneral) {
      this.pageBuilder.relative({ height: 105 }, (pageBuilder) => {
        pageBuilder.addCard(`${caseGrade.score} %`, "Audit Score");
        pageBuilder.addCard(caseGrade.status, "Audit Status", {
          marginLeft: 290,
          width: 370,
        });
      });
    }

    if (isGeneral) this.pageBuilder.addAnswerForm(sections, caseEvaluation);

    if (!isGeneral)
      this.pageBuilder.addTable(
        {
          html: tableSelector,
          headStyles: {
            fillColor: colors.secondary,
            fontSize: PdfPageBuilder.pxToPt(14),
          },
          beforeCellPrint: ({ cell, row, column }) => {
            if (cell.colSpan > 1) {
              for (const cell of Object.values(row.cells)) {
                cell.styles.fillColor = "#C6F2F4";
              }
            }
            if (row.raw._element.classList.contains("failed-row")) {
              cell.styles.fillColor = colors.redLight;
            }
            if (column.index === 1 && row.section === "body") {
              cell.styles.font = "Inter";
              cell.styles.fontStyle = "semibold";

              if (cell.colSpan === 1) {
                cell.styles.fontSize =
                  PdfPageBuilder.pxToPt(16);
              } else {
                cell.styles.fontStyle = "bold";
              }
            }
            if (column.index === 0) {
              cell.styles.halign = "center";
            }
          },
        },
        { margin: 50, marginTop: 25 }
      );

    if (report) {
      this.pageBuilder.addText("Audit Report", [fonts.h3, spacing.mt2]);
      this.pageBuilder.addText(report, spacing.mt4);
    }

    this.pageBuilder.addPageNumbers();
  }

  buildClinicalCaseReport({
    clinicalCase,
    clinician,
    batch,
    audit,
    template,
    resolutionMessage,
    accountLogo,
  }) {
    const { fonts, colors, spacing } = styles;

    const serviceName = clinicalCase.clinicalService.name;

    this.pageBuilder.addAbsoluteRect({
      height: 345,
      backgroundColor: colors.secondary,
    });

    this.pageBuilder.relative({ height: 72 }, (pageBuilder) => {
      const [caseDate] = formatDate(clinicalCase.caseDate).split(",");
      pageBuilder.addText("Case Date", {
        marginLeft: 1057,
        font: ["Inter", "semibold"],
        fontSize: 14,
      });
      pageBuilder.addText(caseDate, [
        { marginLeft: 1057, marginTop: 20, fontSize: 14 },
      ]);
    });

    this.pageBuilder.relative({ height: 130 }, (pageBuilder) => {
      pageBuilder.addText("Case Reference", [
        fonts.subtitle,
        spacing.mt4,
      ]);
      pageBuilder.addText(clinicalCase.caseReference, [
        fonts.h1,
        { marginTop: 55 },
      ]);

      const style = { marginLeft: 892 };
      const categoryColor = clinician?.category
        ? CliniciansStatusColors[clinician.category]
        : CliniciansStatusColors.none;
      pageBuilder.addText(clinicalCase.caseType, [
        fonts.h4,
        spacing.mt4,
        style,
      ]);
      pageBuilder.addCircle(6, {
        backgroundColor: categoryColor,
        marginLeft: 870,
        marginTop: 62,
      });
      pageBuilder.addText(clinicalCase.caseData?.clinician?.name, {
        ...style,
        fontSize: 20,
        marginTop: 50,
      });

      if (clinician?.position) {
        pageBuilder.addText(clinician.position.name, {
          ...style,
          fontSize: 14,
          marginTop: 85,
        });
      }
    });

    this.pageBuilder.addHorizontalLine();

    this.pageBuilder.relative({ height: 105 }, (pageBuilder) => {
      const gender = clinicalCase.sex === "M" ? "Male" : "Female";
      const items = [
        { label: "Priority", value: null, left: 0 },
        {
          label: "Patient Age",
          value: `${clinicalCase.age} years`,
          left: 210,
        },
        { label: "Patient Gender", value: gender, left: 325 },
        { label: "Clinical System", value: "Adastra", left: 470 },
        {
          label: "Location Name",
          value: clinicalCase.locationName,
          left: 615,
        },
        { label: "Case Status", value: null, left: 770 },
        { label: "Case Service", value: serviceName, left: 960 },
      ];

      items.forEach(({ label, value, left: marginLeft }) => {
        pageBuilder.addText(label, [
          spacing.mt2,
          fonts.h4,
          { marginLeft },
        ]);
        if (!value) return;
        pageBuilder.addText(value, {
          fontSize: 14,
          marginTop: 50,
          marginLeft,
        });
      });

      pageBuilder.addBadge(clinicalCase?.latestPriority || "N/A", {
        borderColor: [0, 0, 0, 0.12],
        marginTop: 50,
      });
      pageBuilder.addBadge(audit.caseGrade?.status, {
        borderColor: [0, 0, 0, 0.12],
        marginTop: 50,
        marginLeft: 770,
      });
    });

    if (clinicalCase.reportedCondition) {
      this.pageBuilder.addText("Reported Condition", [
        fonts.h3,
        spacing.mt4,
      ]);
      this.pageBuilder.addText(clinicalCase.reportedCondition, [
        { fontSize: 14 },
        spacing.mt2,
      ]);
    }

    this.pageBuilder.addText(`${clinicalCase.caseType} Summary`, [
      fonts.h3,
      spacing.mt4,
    ]);

    this.pageBuilder.relative({ height: 72 }, (pageBuilder) => {
      const startDate = formatDate(clinicalCase.caseData?.beginTime);
      pageBuilder.addText("Start Time", [
        fonts.h3,
        spacing.mt2,
        { fontSize: 14, color: "#000" },
      ]);
      pageBuilder.addText(startDate, { marginTop: 37 });

      const endDate = formatDate(clinicalCase.caseData?.endTime);
      pageBuilder.addText("End Time", [
        fonts.h3,
        spacing.mt2,
        { fontSize: 14, color: "#000", marginLeft: 220 },
      ]);
      pageBuilder.addText(endDate, { marginTop: 37, marginLeft: 220 });
    });

    this.pageBuilder.addText(clinicalCase.caseData?.summary, {
      marginBottom: resolutionMessage ? 25 : 0,
    });

    if (resolutionMessage?.text) {
      this.pageBuilder.addText("Resolution Note", [
        fonts.h3,
        { marginTop: 16, backgroundColor: "#C6F2F4" },
      ]);
      this.pageBuilder.addText(resolutionMessage?.text, {
        fontSize: 14,
        marginBottom: 16,
        backgroundColor: "#C6F2F4",
      });
    }

    this.pageBuilder.relative(
      { height: 80, marginTop: 25 },
      (pageBuilder) => {
        pageBuilder.addRect({ height: 80, backgroundColor: "#F0F0F0" });

        pageBuilder.addText(batch?.name, [fonts.h3, spacing.mt2]);
        pageBuilder.addText(template?.name, {
          fontSize: 14,
          color: "0, 0, 0, 0.6",
          marginTop: 45,
        });

        pageBuilder.addText(`${audit.caseGrade.score}%`, [
          fonts.h2,
          spacing.mt2,
          { marginLeft: 900 },
        ]);
        pageBuilder.addText("Total Case Score", {
          marginTop: 45,
          marginLeft: 900,
        });

        pageBuilder.addVerticalLine({
          width: 40,
          marginTop: 20,
          marginLeft: 870,
        });
      }
    );

    const records = template.evaluation.sections.reduce(
      (acc, section, index) => [
        ...acc,
        { title: section.title },
        ...section.criteria.map((i, idx) => ({
          criteria: `${String.fromCharCode(index + idx + 65)}.\t${i.text
            }`,
          answer:
            audit.caseEvaluation[i._id] !== -1
              ? audit.caseEvaluation[i._id]
              : "N/A",
        })),
      ],
      []
    );

    const px50 = PdfPageBuilder.pxToInch(50);
    const px10 = PdfPageBuilder.pxToInch(10);
    const px1 = PdfPageBuilder.pxToInch(1);
    const leftCellPadding = {
      cellPadding: { left: px50, right: px10, top: px10, bottom: px10 },
    };
    const rightCellPadding = {
      cellPadding: { right: px50, left: px10, top: px10, bottom: px10 },
    };

    const buildRecordCells = (cell, isLeftTable) => {
      const px3 = PdfPageBuilder.pxToInch(3);
      const leftStyles = {
        fillColor: [255, 255, 255],
        lineWidth: { top: px1 },
        ...leftCellPadding,
      };
      const rightStyles = {
        fillColor: [255, 255, 255],
        lineWidth: { top: px1, ...(isLeftTable ? { right: px3 } : {}) },
        ...rightCellPadding,
      };
      if (cell.title) {
        const cellDef = [
          {
            content: cell.title,
            colSpan: 2,
            styles: {
              ...leftStyles,
              fillColor: [240, 240, 240],
              font: "Inter",
              fontStyle: "semibold",
            },
          },
        ];
        if (isLeftTable) {
          cellDef[0].styles.lineWidth.right = px3;
        }
        return cellDef;
      }

      return [
        { content: cell.criteria, styles: leftStyles },
        {
          content: cell.answer ?? "N/A",
          styles: { halign: "center", ...rightStyles },
        },
      ];
    };

    const rows = [];
    for (let i = 0; i < records.length / 2; i++) {
      const [left, right] = [
        records[i],
        records[Math.floor(i + records.length / 2)],
      ];
      rows.push([
        ...buildRecordCells(left, true),
        ...buildRecordCells(right),
      ]);
    }

    this.pageBuilder.addTable(
      {
        columns: [
          { header: "Criteria" },
          "Answer",
          "Criteria",
          "Answer",
        ],
        body: rows,
        beforeCellPrint: ({ cell, column, row }) => {
          if (cell.section === "head") {
            if ([0, 2].includes(column.index))
              cell.styles.cellPadding =
                leftCellPadding.cellPadding;
            if ([1, 3].includes(column.index))
              cell.styles.cellPadding =
                rightCellPadding.cellPadding;
          }
          if (row.index === rows.length - 1) {
            cell.styles.lineWidth.bottom = px1;
          }
        },
      },
      { marginBottom: 85 }
    );

    this.pageBuilder.addPageNumbers();
    this.pageBuilder.addToEveryPage((pageBuilder) => {
      pageBuilder.addImage(AppLogo, "PNG", [
        spacing.mt4,
        { width: 135, height: 60 },
      ]);

      if (accountLogo)
        this.addAccountLogo(accountLogo, pageBuilder, spacing.mt4);

      pageBuilder.addHorizontalLine({ marginTop: 100 });
    });
  }

  buildStaticReporting({
    type,
    auditTemplateName,
    clinicalServices,
    endDate,
    accountLogo,
    totalAuditsReviewed,
    totalAuditsScore,
    caseAmountReviewed,
    canvas,
    tableSelector,
  }) {
    const { fonts, colors, spacing } = styles;

    const isFiltersPersits =
      type || auditTemplateName || clinicalServices.length || endDate;

    this.pageBuilder.addAbsoluteRect({
      height: isFiltersPersits ? 310 : 110,
      backgroundColor: colors.secondary,
    });

    this.pageBuilder.relative({ height: 82 }, (pageBuilder) => {
      pageBuilder.addImage(AppLogo, "PNG", {
        width: 135,
        height: styles.headerImagesHeight,
      });

      if (accountLogo) this.addAccountLogo(accountLogo, pageBuilder);

      const [date] = formatDate(new Date()).split(",");
      pageBuilder.addText("Report Date", {
        marginLeft: 1057,
        font: ["Inter", "semibold"],
        fontSize: 14,
      });
      pageBuilder.addText(date, {
        marginLeft: 1057,
        marginTop: 20,
        fontSize: 14,
      });
    });

    this.pageBuilder.addHorizontalLine();

    let filtersCount = 0;
    if (isFiltersPersits)
      this.pageBuilder.addText("Filters:", [fonts.h2, spacing.mt2]);

    if (type) {
      filtersCount++;
      this.pageBuilder.addText(`Audit Type: ${type || "All"}`, [
        fonts.subtitle,
        spacing.mt2,
      ]);
    }
    if (auditTemplateName) {
      filtersCount++;
      this.pageBuilder.addText(
        `Audit Template: ${auditTemplateName || "All"}`,
        [fonts.subtitle]
      );
    }
    if (clinicalServices.length) {
      filtersCount++;
      this.pageBuilder.addText(
        `Services: ${clinicalServices?.join(", ") || "None"}`,
        [fonts.subtitle]
      );
    }
    if (endDate) {
      filtersCount++;
      this.pageBuilder.addText(
        `Audit Period: ${endDate.length > 1
          ? endDate.join(" - ")
          : endDate || "All dates"
        }`,
        [fonts.subtitle]
      );
    }

    const negativeMargin = filtersCount * ( filtersCount > 1 ? -20 : 0);

    this.pageBuilder.relative(
      { height: 105, marginTop: isFiltersPersits ? 140 + negativeMargin : 20 },
      (pageBuilder) => {
        pageBuilder.addText("General Audit Report", [
          fonts.h3,
          spacing.mb4,
        ]);
        pageBuilder.addCard(
          totalAuditsReviewed,
          "Total Audits Reviewed",
          {
            marginTop: 40
          }
        );
        pageBuilder.addCard(
          `${totalAuditsScore} %`,
          "Total Audits Score",
          {
            marginTop: 40,
            marginLeft: 290,
          }
        );
        pageBuilder.addCard(caseAmountReviewed, "Total Cases Audited", {
          marginLeft: 580,
          marginTop: 40
        });
      }
    );

    this.pageBuilder.addTable(
      {
        html: tableSelector,
        headStyles: {
          fillColor: colors.secondary,
          fontSize: PdfPageBuilder.pxToPt(14),
        },
        beforeCellPrint: ({ cell, row, column }) => {
          if (column.index === 0 && row.section === "body") {
            cell.styles.font = "Inter";
            cell.styles.fontStyle = "semibold";
          }
        },
      },
      { marginLeft: 50, marginRight: 50, marginTop: 40 }
    );

    const pageImg = canvas.toDataURL("image/png", 1.0);

    this.pageBuilder.addImage(pageImg, "PNG", {
      width: 1145,
      height: 400,
      marginTop: 50,
    });
  }

  getBase64Pdf() {
    return this.pageBuilder.getBase64();
  }

  downloadPdf(filename) {
    const filenameWithoutDots = filename.replaceAll(".", "_");
    return this.pageBuilder.download(`${filenameWithoutDots}-Report`);
  }
}
