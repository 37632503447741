import * as Sentry from "@sentry/vue";

export default {
  install: (Vue, { dsn, router, propagationTargets }) => {
    if (!dsn) return;

    Sentry.init({
      Vue,
      dsn,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: propagationTargets,
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay({ blockAllMedia: true }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0,
      // Session Replay
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
    });

    Sentry.configureScope((scope) => {
      scope.setTag("app", window.location.hostname);
    });
  },
};
