<template>
  <v-select
    :value="status"
    :items="items"
    :item-value="'status'"
    :hide-details="true"
    :disabled="disabled"
    :label="label"
    :menu-props="{ bottom: true, offsetY: true }"
    color="rgba(0, 0, 0, 0.54)"
    height="24"
    solo
    rounded
    dense
    class="case-status-chip"
    @change="onStatusChange"
  >
    <template v-slot:selection="{ item }">
      <div class="d-flex flex-start align-center pa-1" style="width: 100%">
        <v-icon left color="rgba(0, 0, 0, 0.54)" small>
          {{ CasesStatusesIcons[item.status] }}
        </v-icon>
        <span
          class="text-body-2 text-truncate"
          :style="{
            color: CasesAuditStatusColors[item.status],
            fontWeight: 'bold',
          }"
        >
          {{ item.status }}
        </span>
      </div>
    </template>

    <template v-slot:item="{ item }">
      <div class="d-flex flex-start align-center">
        <v-icon left color="rgba(0, 0, 0, 0.54)" small>
          {{ CasesStatusesIcons[item.status] }}
        </v-icon>
        <span class="text-body-2 bold-text">{{ item.status }}</span>
      </div>
    </template>
  </v-select>
</template>

<script>
import { CasesAuditStatusColors, CasesStatusesIcons } from "@/misc/constants";

export default {
  name: "CaseStatusChipSelect",

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Select...",
    },
  },

  data() {
    return {
      selectedStatus: null,
      CasesStatusesIcons,
      CasesAuditStatusColors,
    };
  },
  methods: {
    onStatusChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="scss">
.case-status-chip {
  max-width: 210px;

  .v-input__control {
    min-height: 24px !important;
  }

  .v-input__slot {
    padding: 0 10px !important;
  }

  .v-input__append-inner {
    display: none !important;
  }
}
</style>
