<template>
  <section class="chart-tabs">
    <v-row class="app-container filters-container d-flex mb-3 mt-3" cols="4">
      <v-col class="d-flex" cols="3">
        <ClinicalServicesMultiselect
          ref="clinicalServicesMultiselect"
          :services="filters.clinicalServiceId"
          @change="handleFilterChange($event, 'clinicalServiceId')"
        />
      </v-col>
      <v-col class="d-flex" cols="3">
        <v-select
          :value="filters.type"
          :items="batchTypes"
          :menu-props="{ bottom: true, offsetY: true }"
          label="Audit type"
          item-text="title"
          item-value="value"
          hide-details
          clearable
          outlined
          @change="handleFilterChange($event, 'type')"
        />
      </v-col>
      <v-col class="d-flex" cols="3">
        <AuditTemplateSelect
          :item-value="'_id'"
          :hide-details="false"
          :audit-type-filter="[BatchTypes.specialAudit]"
          @change="handleFilterChange($event, 'auditTemplateId')"
        />
      </v-col>
      <v-col class="d-flex" cols="3">
        <time-data-picker
          ref-name="auditEndDate"
          :date="filters.endDate"
          label="Audit Date"
          @change="handleFilterChange($event, 'endDate')"
        />
      </v-col>
      <v-col class="d-flex filters-buttons flex-end" cols="12">
        <ApplyButton large @click="fetchTabsData" />
        <CancelButton large @click="onFilterCancel" />
      </v-col>
    </v-row>

    <section class="app-container statistic-section">
      <v-row class="d-flex mb-3" cols="4">
        <v-col class="d-flex flex-end">
          <v-btn
            v-if="!isGeneralStatisticLoading"
            color="primary"
            normal
            rounded
            outlined
            variant="elevated"
            class="ml-2"
            @click="onExportReport"
          >
            <v-icon left dark>mdi-download</v-icon>
            Export PDF
          </v-btn>
        </v-col>
      </v-row>

      <v-skeleton-loader
        v-if="isGeneralStatisticLoading"
        type="sentences"
        :types="{ sentences: 'text@5' }"
      />
      <general-tab-static
        v-if="generalReport && !isGeneralStatisticLoading"
        :data="generalReport"
      />

      <v-skeleton-loader
        v-if="isGeneralStatisticLoading"
        type="table"
        class="mt-6"
        :types="{
          'table-row': 'table-cell@10',
          'table-heading': 'heading',
          'table-thead': '',
        }"
      />
      <doc-batch-audit-result
        v-else
        :evaluationResult="generalEvaluationResult"
        class="mt-6 mb-6"
      />

      <Analytics
        v-if="cliniciansStatistic && !isGeneralStatisticLoading"
        :statistics="cliniciansStatistic"
        class="mt-10"
        id="test-chart"
      />
    </section>
  </section>
</template>

<script>
import GeneralTabStatic from "./GeneralTabStatic.vue";
import TimeDataPicker from "@/components/controls/TimeDataPicker.vue";
import { getGeneralBatchReport } from "../../services/batch";
import { BatchTypes, BatchTypesTitles } from "@/misc/constants";
import ApplyButton from "@/components/common/filterControls/ApplyButton.vue";
import AuditTemplateSelect from "@/components/controls/AuditTemplateSelect.vue";
import CancelButton from "@/components/common/filterControls/CancelButton.vue";
import ClinicalServicesMultiselect from "@/components/controls/ClinicalServicesMultiselect.vue";
import DocBatchAuditResult from "../batch/DocBatchAuditResult.vue";
import { getCliniciansStatistics } from "../../services/statistics";
import Analytics from "../home/analytics/Analytics.vue";
import { mapState } from "pinia";
import { useAccountStore } from "@/stores/account";
import html2canvas from "html2canvas-pro";

export default {
  name: "ReportingTabs",

  components: {
    GeneralTabStatic,
    TimeDataPicker,
    ApplyButton,
    CancelButton,
    DocBatchAuditResult,
    ClinicalServicesMultiselect,
    Analytics,
    AuditTemplateSelect,
  },

  data() {
    return {
      isGeneralStatisticLoading: true,
      generalReport: null,
      cliniciansStatistic: null,
      auditTemplates: [],
      generalEvaluationResult: [],
      cdaEvaluationResult: null,
      accountLogoEncoded: null,
      BatchTypes,
      BatchTypesTitles,
      batchTypes: [
        {
          value: BatchTypes.docReview,
          title: BatchTypesTitles.docReview,
        },
        {
          value: BatchTypes.general,
          title: BatchTypesTitles.general,
        },
      ],
      filters: {
        type: null,
        auditTemplateId: null,
        clinicalServiceId: null,
        endDate: null,
      },
    };
  },

  async mounted() {
    await this.fetchTabsData();
  },

  methods: {
    async fetchTabsData() {
      this.isGeneralStatisticLoading = true;

      try {
        const query = {
          filters: this.buildFilters(),
        };

        await this.getClinicianStatistics();
        const audits = await getGeneralBatchReport(query);
        this.generalReport = this.generateGeneralData(audits);
        this.generalEvaluationResult = this.generateEvaluationResult(audits);
      } catch (err) {
        this.$notify({
          type: "error",
          text: err?.message || JSON.stringify(err),
        });
      }

      this.isGeneralStatisticLoading = false;
    },
    async getClinicianStatistics() {
      const filters = {
        ...(this.filters.clinicalServiceId && {
          clinicalServices: { $in: this.filters.clinicalServiceId },
        }),
      };

      const queryParams = { filters: JSON.stringify(filters) };
      try {
        this.cliniciansStatistic = await getCliniciansStatistics(queryParams);
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Fetch statistics error",
          text: e?.message || JSON.stringify(e),
        });
      }
    },
    handleFilterChange(value, key) {
      this.filters[key] = value;
    },
    buildFilters() {
      const filters = {};
      if (Object.values(this.filters).some((val) => !!val === true)) {
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) {
            if (key === "endDate" || key === "createdAt") {
              const [first, second] = this.filters[key];
              const startDate = new Date(first);

              // add one day if second is empty
              const endDate = second
                ? new Date(second)
                : new Date(startDate.getTime() + 86400000);
              filters[key] = { $gte: startDate, $lt: endDate };
            } else {
              filters[key] = this.filters[key];
            }
          }
        });
      }
      return JSON.stringify(filters);
    },
    async onFilterCancel() {
      this.getServiceNamesFromRef();
      Object.keys(this.filters).forEach((key) => (this.filters[key] = null));
      await this.fetchTabsData();
    },
    generateGeneralData(audits) {
      const generalData = audits.reduce(
        (acc, audit) => {
          // Calculate only Doc. Review Audits
          const docReviewCases =
            audit.type === BatchTypes.docReview ? audit.caseAmountReviewed : 0;

          return {
            totalAuditsScore: acc.totalAuditsScore + audit.sumAuditsScore,
            caseAmountReviewed: acc.caseAmountReviewed + docReviewCases,
          };
        },
        {
          totalAuditsScore: 0,
          caseAmountReviewed: 0,
        }
      );
      generalData.totalAuditsReviewed = audits.length;
      generalData.totalAuditsScore = Math.floor(
        generalData.totalAuditsScore / (audits.length || 1)
      );

      return generalData;
    },
    generateEvaluationResult(audits) {
      const totalAuditsReviewed = this.generalReport?.totalAuditsReviewed;
      const scoresCalculations = {
        score90Amount: 0,
        score75Amount: 0,
        less75Amount: 0,
      };

      audits.forEach((audit) => {
        if (audit.sumAuditsScore >= 90) scoresCalculations.score90Amount++;
        if (audit.sumAuditsScore < 90 && audit.sumAuditsScore >= 75)
          scoresCalculations.score75Amount++;
        if (audit.sumAuditsScore < 75) scoresCalculations.less75Amount++;
      });

      return [
        {
          name: "Audit Score ≥ 90%",
          amount: scoresCalculations.score90Amount,
          percent: this.calculatePercent(
            scoresCalculations.score90Amount,
            totalAuditsReviewed
          ),
          className: "green-evaluation",
        },
        {
          name: "Audit Score ≥ 75%, Score < 90%",
          amount: scoresCalculations.score75Amount,
          percent: this.calculatePercent(
            scoresCalculations.score75Amount,
            totalAuditsReviewed
          ),
          className: "amber-evaluation",
        },
        {
          name: "Audit Score < 75%",
          amount: scoresCalculations.less75Amount,
          percent: this.calculatePercent(
            scoresCalculations.less75Amount,
            totalAuditsReviewed
          ),
          className: "red-evaluation",
        },
      ];
    },
    calculatePercent(amount, total) {
      const zeroHandler = (number) => number || 1;
      return ((amount * 100) / zeroHandler(total)).toLocaleString("en", {
        maximumFractionDigits: 2,
      });
    },
    async onExportReport() {
      const { AuditReportFactory } = await import("@/libs/AuditReportFactory");
      const chart = document.getElementById("test-chart");
      chart.classList.toggle("chart-import");

      const canvas = await html2canvas(chart, { backgroundColor: null });

      const reportFactory = new AuditReportFactory();
      let clinicalServices = [];

      if (this.filters.clinicalServiceId?.length) {
        const servicesFromMultiselect = this.getServiceNamesFromRef();
        clinicalServices = this.filters.clinicalServiceId.map((id) => {
          return servicesFromMultiselect.find((service) => service._id === id)
            ?.name;
        });
      }

      const auditTemplateName = this.auditTemplates.find(
        (template) => template._id === this.filters.auditTemplateId
      )?.name;

      reportFactory.buildStaticReporting({
        ...this.generalReport,
        ...this.filters,
        auditTemplateName,
        clinicalServices,
        accountLogo: this.getEncodedLogoImage,
        canvas,
        tableSelector: "#audit-result-table table",
      });
      reportFactory.downloadPdf(`${new Date().toLocaleDateString()}`);
      chart.classList.remove("chart-import");
    },
    getServiceNamesFromRef() {
      return this.$refs?.clinicalServicesMultiselect?.clinicalServices;
    },
  },
  computed: {
    ...mapState(useAccountStore, ["getEncodedLogoImage"]),
  },
};
</script>

<style lang="scss">
.chart-tabs {
  .tabs-container {
    &__tab--active {
      color: var(--v-text-black-base);
    }

    .v-item-group {
      background-color: #f0f0f0 !important;
    }
  }

  .filters-container {
    padding: 4px;
    margin: 0 auto;
  }

  .flex-end {
    justify-content: flex-end;
  }

  .filters-buttons {
    align-items: center;
    gap: 20px;
  }

  .v-pagination {
    &__navigation {
      box-shadow: none;
    }

    &__item {
      box-shadow: none;

      &--active {
        background: #f0f0f0 !important;
        color: var(--v-text-black-base) !important;
      }
    }
  }

  .chart-import {
    box-shadow: unset !important;
    border: 1px solid slategray;
  }
}
</style>
