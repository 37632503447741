<template>
  <section class="mb-5">
    <v-btn
      color="primary"
      rounded
      :outlined="selectedFilter !== 'thisMonth'"
      normal
      variant="elevated"
      class="ml-2"
      @click="generateThisMonth"
    >
      This Month
    </v-btn>
    <v-btn
      color="primary"
      rounded
      :outlined="selectedFilter !== 'lastMonth'"
      normal
      variant="elevated"
      class="ml-2"
      @click="generateLastMonth"
    >
      Last Month
    </v-btn>
    <v-btn
      color="primary"
      rounded
      :outlined="selectedFilter !== 'openAudits'"
      variant="elevated"
      class="ml-2"
      @click="generateOpenAudits"
    >
      Open Audits
    </v-btn>
  </section>
</template>

<script>
import { BatchStatuses } from "@/misc/constants";

export default {
  name: "BatchesQuickFilters",
  data() {
    return {
      selectedFilter: null,
    };
  },
  methods: {
    generateThisMonth() {
      this.selectedFilter = "thisMonth";
      let currentDate = new Date();

      let firstDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );

      let filter = {
        endDate: [
          firstDay.toISOString().split("T")[0],
          currentDate.toISOString().split("T")[0],
        ],
      };
      this.$emit("change", filter);
    },
    generateLastMonth() {
      this.selectedFilter = "lastMonth";
      let currentDate = new Date();
      let firstDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );

      let lastDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      );

      let filter = {
        endDate: [
          firstDay.toISOString().split("T")[0],
          lastDay.toISOString().split("T")[0],
        ],
      };
      this.$emit("change", filter);
    },
    generateOpenAudits() {
      this.selectedFilter = "openAudits";
      let filter = { status: BatchStatuses.opened };
      this.$emit("change", filter);
    },
  },
};
</script>
